import { fetcher } from '../fetcher';

const COURSE_ENDPOINT = '/courses';

export const courseService = {
  getKeepWatching: async () => {
    return await fetcher(`${COURSE_ENDPOINT}/keep-watching`, {
      method: 'GET',
      schema: keepWatchingsSchema,
    });
  },
  getUserCourses: async () => {
    return await fetcher(`${COURSE_ENDPOINT}`, {
      method: 'GET',
      schema: coursesSchema,
    });
  },
  getWatchAgain: async () => {
    return await fetcher(`${COURSE_ENDPOINT}/watch-again`, {
      method: 'GET',
      schema: keepWatchingsSchema,
    });
  },
  getAllCourses: async () => {
    return await fetcher(`${COURSE_ENDPOINT}/list/all`, {
      method: 'GET',
      schema: coursesListSchema,
    });
  },
  getCourse: async (payload: { slug: string }) => {
    return await fetcher(`/course/${payload.slug}`, {
      method: 'GET',
      schema: checkoutCourseSchema,
    });
  },
};
